import axios from "axios";
import appInsights from "./AppInsightsService";

const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
    "Content-Type": "application/json",
};
export const fetchPendingInspect = async () => {
    try {
        const config =
        {
            headers: headers,
            method: 'get',
            url: process.env.REACT_APP_GET_PENDING_INSPECT
        }
        const response = await axios(config);
        return response.data
    } catch (error) {
        console.log(error)
    }
}
export const requestBadGoods = async (data) => {
    try {
        const config = {
            headers: headers,
            method: 'post',
            url: process.env.REACT_APP_REQUEST_BAD_GOODS,
            timestamp: new Date().getTime(),
            data: data
        }
        const response = await axios(config);
        console.log(response)
        appInsights.actions.sendAppInsightEvent(
            { commit: null },
            {
                properties: {
                    eventName: 'Request Post Bad Goods',
                    success: true,
                    function: process.env.REACT_APP_REQUEST_BAD_GOODS,
                    payload: data, // Puedes enviar datos adicionales si es necesario
                    response: response,
                    latency: new Date().getTime() - config.timestamp,
                    user: data.userName,
                },

            }
        );
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const aproveInspection = async (data) => {
    try {
        const config = {
            headers: headers,
            method: 'post',
            url: process.env.REACT_APP_APROVE_INSPECTION,
            data: data
        }
        const response = await axios(config);
        return response;
    }
    catch (error) {
        console.log(error)
    }
}

export const sendComments = async (data, user) => {
    try {
        const config = {
            headers: headers,
            method: 'post',
            url: process.env.REACT_APP_SEND_COMMENTS,
            data: data
        }
        const response = await axios(config);
        appInsights.trackPostRequest({
            name: 'sendComments',
            properties: {

                eventName: 'YMS-INSPECT-Send Comments',
                success: true,
                function: process.env.REACT_APP_SEND_COMMENTS,
                payload: data, // Puedes enviar datos adicionales si es necesario
                response: response,
                user: user

            }
        }
        )
        return response;
    }
    catch (error) {
        console.log(error)
    }

}

export const getLocationsList = async () => {
    try {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_GET_LOCATIONS_URL
        }
        const response = await axios(config);
        return response
    } catch (error) {
        console.log(error)
    }
}