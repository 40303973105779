import * as React from 'react';
import { StoreContext } from '../store/storeContext';
import { sendComments } from '../services/services';
const useModalController = () => {

    const {
        showModal,
        setShowModal,
        rowData,
        setRowData,
        user,
        fetchData,
        setLoading,
        setToast
    } = React.useContext(StoreContext);

    const [fullWidth, setFullWidth] = React.useState(true);
    const handleSaveComment = () => {
        setLoading(true)
        const object = {
            id: rowData.id,
            userName: user.email[0],
            comment: rowData.Comment
        }
        sendComments(object, user).then((response) => {
            fetchData()
            setToast({ state: true, severity: 'success', message: 'Your comment was saved' })
        })
        setShowModal(false)
    }

    return {
        showModal,
        setShowModal,
        rowData,
        setRowData,
        user,
        fetchData,
        setLoading,
        fullWidth,
        setFullWidth,
        handleSaveComment
    }
}
export default useModalController;