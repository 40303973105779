import { TextField, Select, InputLabel, MenuItem, FormControl } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'
import useFieldRowController from "../../controllers/fieldRowController";
const FieldRow = () => {
    const { locations, locationSelected, setLocationSelected, containerFiltered, setContainerFiltered } = useFieldRowController()


    return (
        <>
            <Grid container spacing={2} paddingBottom={'25px'}>

                <Grid xxl={2} xl={2} lg={2} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Locations</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Locations"
                            value={locationSelected}
                            onChange={(e) => setLocationSelected(e.target.value)}
                        >
                            {locations.map((location, index) => (
                                <MenuItem key={index} value={location.value}>{location.text}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xxl={2} xl={2} lg={2} md={4} sm={6} xs={12}>
                    <TextField
                        id="filled-multiline-static"
                        label="Search By Container"
                        fullWidth

                        variant="outlined"
                        value={containerFiltered}
                        onChange={(e) => setContainerFiltered(e.target.value.toUpperCase())}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default FieldRow;