import { Snackbar, IconButton, Alert } from '@mui/material'
import { StoreContext } from '../../store/storeContext';
import * as React from 'react';
const Toast = () => {

    const { toast, setToast } = React.useContext(StoreContext);
    return (<>
        <Snackbar
            open={toast.state}
            autoHideDuration={6000}
            onClose={() => setToast({ ...toast, state: false })}
        >
            <Alert severity={toast.severity}>{toast.message}</Alert>
        </Snackbar>

    </>
    )

}
export default Toast