import * as React from 'react'
import { StoreContext } from '../store/storeContext'
import { getLocationsList } from '../services/services'

const useFieldRowController = () => {
    const { locationSelected, setLocationSelected, containerFiltered, setContainerFiltered } = React.useContext(StoreContext)
    const [locations, setLocations] = React.useState([])

    React.useEffect(() => {
        try {
            getLocationsList().then((response) => {
                setLocations(response.data)
            })
        } catch (error) {
            console.error('Error fetching locations:', error)
        }
    }, [])
    return {
        locations,
        locationSelected,
        setLocationSelected,
        containerFiltered,
        setContainerFiltered
    }
}
export default useFieldRowController