import Typography from '@mui/material/Typography'
import Table from './components/Table/table'
import ModalComments from './components/ModalComments/modalComments'
import Toast from './components/Snackbar/snackbar'
import FieldRow from './components/fieldRow/fieldRow'
import './App.css'

import { StoreProvider } from './store/storeContext'

export default function Root(props) {
  return <section id="App-templates-pending-inspect">
    <StoreProvider>
      <div style={{ padding: '3%' }}>
        <ModalComments />
        <FieldRow />
        <Table />
        <Toast />
      </div>
    </StoreProvider>
  </section>;
}
