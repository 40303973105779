
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey:
            process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
        enableAutoRouteTracking: true,
    },
});

appInsights.loadAppInsights();
// appInsights.trackPageView();

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        // eslint-disable-next-line no-unused-vars
        sendAppInsightEvent({ commit }, { properties }) {
            appInsights.trackEvent(
                {
                    name: properties.eventName,
                },
                {
                    properties: {},
                    success: properties.success,
                    function: properties.function,
                    payload: properties.payload,
                    response: properties.response,
                    timetaken: properties.latency,
                    user: properties.user,
                }
            );
        },
        // eslint-disable-next-line no-unused-vars
        sendAppInsightPageView({ commit }, { properties }) {
            appInsights.trackPageView({
                name: properties.pageName,
                uri: properties.uri,
                refUri: properties.refUri,
                properties: {
                    duration: properties.latency,
                },
            });
        },
        // eslint-disable-next-line no-unused-vars
        sendAppInsightException({ commit }, { properties }) {
            appInsights.trackException(
                {
                    error: new Error(properties.error),
                },
                {
                    properties: {},
                    success: properties.success,
                    function: properties.function,
                    payload: properties.payload,
                    user: properties.user,
                }
            );
        },
    },
};
