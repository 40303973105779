import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import useTableController from '../../controllers/tableController';
import useButtonsController from '../../controllers/buttonsController';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CssBaseline, Container } from '@mui/material';
import './table.css';

const Table = () => {

    const { tableLocalData, columns, getRowId, loading, handleExportExcel, data, filteredData } = useTableController();
    const { handleReloadData } = useButtonsController();


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }


    return (
        <>
            <CssBaseline />

            <Paper elevation={12}
                sx={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
            >
                <Typography id='titleTable-pending-inspect'>Empties To Inspect</Typography>

                <DataGrid
                    style={{ textAlign: 'center', height: '70vh' }}
                    rows={filteredData || []}
                    columns={columns}
                    getRowId={getRowId}
                    autoHeight
                    density="compact"

                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 100,
                                currentPage: 1,
                            },
                        },
                    }}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}

                    sx={{
                        '& .super-app.good': {
                            color: '#2acb66',
                        },
                        '& .super-app.bad': {
                            color: 'red',
                        },
                    }}

                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    disableRowSelectionOnClick
                    loading={loading}
                />
            </Paper>
        </>
    );
};

export default Table;
